<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-form-checkbox
      checked=""
      v-model="priorizar"
      class="custom-control-success text-center mt-25"
      name="check-button"
      switch
      @change="changePriorizar(item.id)"
    >
      <span class="switch-icon-left">
        <feather-icon icon="StarIcon" />
      </span>
    </b-form-checkbox>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BFormCheckbox,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      spinner: false,
      priorizar: null,
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  watch: {
    item(item) {
      this.cargaPriorizacion(item)
    },
  },
  mounted() {
    this.cargaPriorizacion(this.item)
  },
  methods: {
    ...mapActions({
      updatePriorizacionPersonalizado: 'objetivos/updatePriorizacionPersonalizado',
      updatePriorizacionInternaMinisterio: 'objetivos/updatePriorizacionInternaMinisterio',
    }),
    cargaPriorizacion(item) {
      this.priorizar = item.priorizacionInterna === 1 || item.priorizacionInterna === '1'
    },
    changePriorizar(id) {
      const data = {
        id,
        priorizacion: this.priorizar ? 1 : null,
        idEstablecimiento: this.item.idEstablecimiento,
      }
      if (!this.item.idEstablecimiento) {
        this.updatePriorizacionInternaMinisterio(data).then(() => {
          this.mensaje(data)
        })
      } else {
        this.updatePriorizacionPersonalizado(data).then(() => {
          this.mensaje(data)
        })
      }
    },
    mensaje(data) {
      const statusObjetivo = store.state.objetivos.API_RESPONSE
      if (statusObjetivo === 'success') {
        let title
        let variant
        if (this.priorizar) {
          title = 'priorizado'
          variant = 'success'
        } else {
          title = 'no priorizado'
          variant = 'danger'
        }

        if (!data.idEstablecimiento) {
          this.$emit('changePriorizar', data)
          title = `${this.item.abreviatura} ministerial ${title} 👍`
        } else {
          this.$emit('changePriorizarPersonalizado', data)
          title = `${this.item.abreviatura} personalizado ${title} 👍`
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'CheckIcon',
            text: 'La priorización fue guardada con éxito!',
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout: 4000,
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: store.state.personas.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
