<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-form-checkbox
      checked="1"
      v-model="autorizar"
      class="custom-control-success text-center mt-25"
      name="check-button"
      switch
      @change="changeAutorizar(item.id)"
    >
      <span class="switch-icon-left">
        <feather-icon icon="CheckIcon" />
      </span>
    </b-form-checkbox>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BFormCheckbox,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      spinner: false,
      autorizar: null,
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  watch: {
    item(item) {
      this.cargaAutorizacion(item)
    },
  },
  mounted() {
    this.cargaAutorizacion(this.item)
  },
  methods: {
    ...mapActions({
      updateEstadoMinisterio: 'objetivos/updateEstadoMinisterio',
      updateEstadoPersonalizado: 'objetivos/updateEstadoPersonalizado',
    }),
    cargaAutorizacion(item) {
      this.autorizar = item.estado === 'Activo'
    },
    changeAutorizar(id) {
      const data = {
        id,
        estado: this.autorizar ? 'Activo' : 'Inactivo',
      }
      if (typeof this.item.idEstablecimiento === 'undefined') {
        // Objetivo Ministerio
        this.updateEstadoMinisterio(data).then(() => {
          this.mensaje()
          this.$emit('changeAutorizar', data)
        })
      } else {
        // Objetivo Interno
        this.updateEstadoPersonalizado(data).then(() => {
          this.mensaje()
          const datos = {
            id,
            estado: this.autorizar ? 'Activo' : 'Inactivo',
            idEstablecimiento: this.item.idEstablecimiento,
          }
          this.$emit('changeAutorizarPersonalizado', datos)
        })
      }
    },
    mensaje() {
      const statusObjetivo = store.state.objetivos.API_RESPONSE
      if (statusObjetivo === 'success') {
        let title
        let variant
        if (this.autorizar) {
          title = `${this.item.abreviatura} activado 👍`
          variant = 'success'
        } else {
          title = `${this.item.abreviatura} desactivado 👍`
          variant = 'danger'
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'CheckIcon',
            text: 'La autorización fue guardada con éxito!',
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout: 4000,
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: store.state.personas.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
