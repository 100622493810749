<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="12"
          md="6"
        >
          <b-overlay
            :show="cargaGrados"
            spinner-variant="primary"
          >
            <b-form-select
              v-model="selectGrado"
              :options="optionsGrados"
              @change="changeGrado"
            />
          </b-overlay>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-overlay
            :show="cargaAsignaturas"
            spinner-variant="primary"
          >
            <b-form-select
              v-model="selectAsignatura"
              :options="optionsAsignaturas"
              :disabled="disabledAsignatura"
              @change="changeAsignatura"
            />
          </b-overlay>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="items.length"
          />
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="1"
          class="my-1"
        >
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <!-- BOTON CREAR -->
        <b-col
          md="3"
          sm="3"
          class="my-1"
        >
          <btnCrear
            texto="Objetivo"
            modulo="objetivos"
            @processAdd="goToAddObjetivo"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            :tbody-tr-class="rowClass"
            @filtered="onFiltered"
          >

            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Column: PRIORIZACION -->
            <template #cell(priorizacion)="data">
              <feather-icon
                v-if="typeof data.item.idEstablecimiento === 'undefined' && data.item.priorizacion !== null"
                icon="StarIcon"
                :class="[{'fill-current': data.item.priorizacion === 1 }
                    , 'text-dark'
                  ]"
              />
            </template>

            <!-- Column: PRIORIZACION PERSONALIZADA -->
            <template #cell(priorizacionEstablecimiento)="data">
              <objetivos-priorizar
                :item.sync="data.item"
                @changePriorizar="changePriorizar"
              />
            </template>

            <!-- Column: AUTORIZACION -->
            <template #cell(estado)="data">
              <objetivos-autorizar
                :item.sync="data.item"
                @changeAutorizar="changeAutorizar"
                @changeAutorizarPersonalizado="changeAutorizarPersonalizado"
              />
            </template>

            <!-- Column: ACCIONES -->
            <template #cell(acciones)="data">
              <col-acciones-btnes-objetivos
                modulo="objetivos"
                :data="data"
                :clone="true"
                @processGoToUpdate="goToUpdate"
                @processGoToClone="goToClone"
                @processRemove="remove(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>

      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BOverlay, BCard, BFormSelect,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

// COMPONENTES RECICLADOS
import inputFiltro from '../components/List/inputFiltro.vue'
import btnMostrar from '../components/List/btnMostrar.vue'
import btnCrear from '../components/List/btnCrear.vue'
import colAccionesBtnesObjetivos from '../components/List/colAccionesBtnesObjetivos.vue'
import spinner from '../components/spinner.vue'

// COMPONENTES HIJOS
import objetivosAutorizar from './components/colObjetivosAutorizar.vue'
import objetivosPriorizar from './components/colObjetivosPriorizar.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BCard,
    BFormSelect,

    // COMPONENTES RECICLADOS
    inputFiltro,
    btnMostrar,
    btnCrear,
    colAccionesBtnesObjetivos,
    spinner,

    // COMPONENTES HIJOS
    objetivosAutorizar,
    objetivosPriorizar,
  },
  data() {
    return {
      cargando: true,
      cargaGrados: true,
      cargaAsignaturas: true,
      spinner: false,
      disabledAsignatura: true,
      // FILTROS
      selectGrado: null,
      selectAsignatura: null,
      optionsGrados: [],
      optionsAsignaturas: [],

      // chk
      items: [],
      itemsGet: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 100,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [100, 200, 300],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombreCurso',
          label: 'Grado',
          sortable: false,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreAsignatura',
          label: 'Asignatura',
          sortable: true,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreEje',
          label: 'Eje',
          sortable: false,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'abreviatura',
          label: 'OA',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '70px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreObjetivo',
          label: 'Nombre',
          sortable: false,
          thStyle: {
            width: '500px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },

        {
          key: 'priorizacion',
          label: 'PM',
          headerTitle: 'Priorización Ministerial',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '70px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },

        {
          key: 'priorizacionEstablecimiento',
          label: 'PI',
          headerTitle: 'Priorización Interna (Oficial en el sistema)',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '70px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Autorizar',
          headerTitle: 'Autoriza el desarrollo del objetivo en el sistema',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '150px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getObjetivos: 'objetivos/getObjetivos',
      user: 'auth/user',
      getGrados: 'grados/getGrados',
      getAsignaturasActivosGrado: 'asignaturas/getAsignaturasActivosGrado',
    }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  watch: {
    getObjetivos(val) {
      this.totalRows = val.length
      this.getObjetivos.forEach(element => {
        this.items.push(element)
        this.itemsGet.push(element)
      })
      this.cargando = false
    },
    chkTodo() {
      this.chkAll()
    },
  },
  mounted() {
    this.cargarObjetivos()
    this.setTableList()
    this.cargarGrados()
  },
  methods: {
    ...mapActions({
      fetchObjetivos: 'objetivos/fetchObjetivos',
      fetchGrados: 'grados/fetchGrados',
      fetchAsignaturasActivosGrado: 'asignaturas/fetchAsignaturasActivosGrado',
    }),
    ...mapMutations('objetivos', ['setObjetivo']),

    // PERMISOS
    setTableList() {
      if (this.$can('update', 'objetivos')
        || this.$can('delete', 'objetivos')
      ) {
        this.fieldAcciones.forEach(field => {
          this.fields.push(field)
        })
      }
    },

    // CARGAR
    setAsignaturasOptions(asignaturas) {
      this.optionsAsignaturas = []
      this.optionsAsignaturas.push({
        value: null,
        text: 'Buscar por asignatura...',
      })
      asignaturas.forEach(asignatura => {
        this.optionsAsignaturas.push({
          value: asignatura.id,
          text: asignatura.nombreAsignatura,
        })
      })
      this.disabledAsignatura = false
      this.cargaAsignaturas = false
    },
    cargarObjetivos() {
      this.fetchObjetivos().then(() => {
        this.ordenarObjetivos()
      })
    },
    ordenarObjetivos() {
      this.items.sort((a, b) => (a.idGrado - b.idGrado || a.idAsignatura - b.idAsignatura || a.idEje - b.idEje || a.abreviatura.localeCompare(b.abreviatura)))
      this.itemsGet.sort((a, b) => (a.idGrado - b.idGrado || a.idAsignatura - b.idAsignatura || a.idEje - b.idEje || a.abreviatura.localeCompare(b.abreviatura)))
    },

    // FILTROS
    cargarGrados() {
      this.fetchGrados().then(() => {
        const grados = this.getGrados.filter(gg => gg.idNivel === 110 || gg.idNivel === 10)
        this.optionsGrados.push({
          value: null,
          text: 'Buscar por grado...',
        })
        this.optionsAsignaturas.push({
          value: null,
          text: 'Buscar por asignatura...',
        })
        grados.forEach(grado => {
          this.optionsGrados.push({
            value: grado.id,
            text: grado.nombre,
          })
        })
        this.cargaGrados = false
        this.cargaAsignaturas = false
      })
    },
    changeGrado() {
      this.cargando = true
      this.selectAsignatura = null
      this.disabledAsignatura = true
      if (this.selectGrado) {
        this.cargaAsignaturas = true
        this.fetchAsignaturasActivosGrado(this.selectGrado).then(() => {
          this.setAsignaturasOptions(this.getAsignaturasActivosGrado)
        })
      }
      this.filtroGrado()
    },
    changeAsignatura() {
      this.cargando = true
      this.filtroAsignatura()
    },
    filtroGrado() {
      this.items = []
      this.itemsGet.forEach(item => {
        this.items.push(item)
      })
      if (this.selectGrado) {
        const filtro = this.items.filter(i => i.idGrado === this.selectGrado)
        this.items = filtro
      }
      this.totalRows = this.items.length
      this.cargando = false
    },
    filtroAsignatura() {
      if (this.selectAsignatura) {
        this.items = []
        this.itemsGet.forEach(item => {
          this.items.push(item)
        })
        const filtro = this.items.filter(i => i.idAsignatura === this.selectAsignatura)
        this.items = filtro
        this.items.sort((a, b) => (a.abreviatura.localeCompare(b.abreviatura)))
        this.totalRows = this.items.length
        this.cargando = false
      } else {
        this.filtroGrado()
      }
    },

    // CHANGE
    changePriorizar(data) {
      const item = this.items.find(i => i.idEstablecimiento === undefined && i.id === data.id)
      item.priorizacionInterna = data.priorizacion
    },

    changePriorizarPersonalizar(data) {
      const item = this.items.find(i => i.idEstablecimiento !== undefined && i.id === data.id)
      item.priorizacionInterna = data.priorizacion
    },
    changeAutorizar(data) {
      const item = this.items.find(i => i.idEstablecimiento === undefined && i.id === data.id)
      item.estado = data.autorizacion
    },
    changeAutorizarPersonalizado(data) {
      const item = this.items.find(i => i.idEstablecimiento !== undefined && i.id === data.id)
      item.estado = data.autorizacion
    },

    // ROUTER TO
    goToAddObjetivo() {
      this.$router.replace({
        name: 'objetivos-create',
      })
    },
    goToUpdate(objetivo) {
      this.setObjetivo(objetivo)
      this.$router.replace({
        name: 'objetivos-update',
      })
    },
    goToClone(objetivo) {
      this.setObjetivo(objetivo)
      this.$router.replace({
        name: 'objetivos-clone',
      })
    },
    remove() {
    },

    // COLOR OBJETIVOS PERSONALIZADOS
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (typeof item.idEstablecimiento !== 'undefined') { return colorClass }
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
